<template>
  <header v-if="userAgentDetails['screenWidth'] >= 992" class="header-common">
    <!-- Top Header -->
    <div class="top-header bg-vareni-green" style="height: 42px !important">
      <p class="marquee">
        <span style="font-weight: 400; color: var(--theme-color-brown)">
          جشنواره فروش زمستانی کفش - 50% تخفیف! اکنون خرید کنید</span
        >
      </p>
    </div>
    <div
      class="container-lg"
      style="
        padding-left: var(--bs-gutter-x, 1rem) !important;
        padding-right: var(--bs-gutter-x, 1) !important;
      "
    >
      <div class="nav-wrap mt-4">
        <!-- Navigation Start -->
        <nav class="navigation">
          <div class="nav-section">
            <div class="header-section">
              <div
                class="navbar navbar-expand-xl navbar-light navbar-sticky p-0"
              >
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#primaryMenu"
                  aria-controls="primaryMenu"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <a
                  href="/"
                  class="logo-link"
                  style="margin-left: unset !important"
                >
                  <img
                    class="logo logo-dark"
                    src="/assets/images/logo2.png"
                    style="width: 210px"
                    alt="logo1"
                  />
                  <img
                    class="logo logo-light"
                    src="/assets/images/logo2.png"
                    style="width: 210px"
                    alt="logo2"
                  />
                </a>
                <div
                  class="offcanvas offcanvas-collapse order-lg-2"
                  id="primaryMenu"
                >
                  <div class="offcanvas-header navbar-shadow">
                    <h5 class="mt-1 mb-0">منو</h5>
                    <button
                      class="btn-close lead"
                      type="button"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="input-group mb-2 mr-sm-2">
                    <input
                      type="text"
                      class="form-control"
                      style="
                        background-color: #efefef;
                        border: unset;
                        width: 490px;
                        height: 42px;
                      "
                      id="inlineFormInputGroupUsername2"
                      placeholder="جست و جوی محصولات"
                    />
                    <div class="input-group-prepend">
                      <div
                        class="input-group-text"
                        style="
                          background-color: #efefef;
                          border: unset;
                          height: 42px;
                        "
                      >
                        <i class="iconsax" icon-name="search-normal-2"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <!-- Navigation End -->

        <!-- Menu Right Start  -->
        <div class="menu-right">
          <!-- Icon Menu Start -->
          <ul class="icon-menu" style="width: 280px !important">
            <li>
              <button class="search-button">
                <i data-feather="search"></i>
              </button>
              <!-- Search Input Start -->
              <div class="search-full">
                <div class="input-group">
                  <span class="input-group-text">
                    <i data-feather="search"></i>
                  </span>
                  <input
                    type="text"
                    class="form-control search-type"
                    placeholder="اینجا سرچ کن.."
                  />
                  <span class="input-group-text close-search">
                    <i data-feather="x"></i>
                  </span>
                </div>

                <!-- Suggestion Start -->
                <div class="search-suggestion">
                  <ul>
                    <li>
                      <div class="product-cart media">
                        <img
                          src="/assets/images/fashion/product/front/4.jpg"
                          class="img-fluid blur-up lazyload"
                          alt=""
                        />
                        <div class="media-body">
                          <a href="javascript:void(0)">
                            <h6>کتونی زنانه</h6>
                          </a>
                          <ul class="rating p-0 mb">
                            <li>
                              <i class="fill" data-feather="star"></i>
                            </li>
                            <li>
                              <i class="fill" data-feather="star"></i>
                            </li>
                            <li>
                              <i class="fill" data-feather="star"></i>
                            </li>
                            <li>
                              <i class="fill" data-feather="star"></i>
                            </li>
                            <li>
                              <i data-feather="star"></i>
                            </li>
                          </ul>
                          <p class="mb-0 mt-1">280 تومان</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="product-cart media">
                        <img
                          src="/assets/images/fashion/product/front/5.jpg"
                          class="img-fluid blur-up lazyload"
                          alt=""
                        />
                        <div class="media-body">
                          <a href="javascript:void(0)">
                            <h6>لباس کوتاه پیراهن</h6>
                          </a>
                          <ul class="rating p-0">
                            <li>
                              <i class="fill" data-feather="star"></i>
                            </li>
                            <li>
                              <i class="fill" data-feather="star"></i>
                            </li>
                            <li>
                              <i class="fill" data-feather="star"></i>
                            </li>
                            <li>
                              <i class="fill" data-feather="star"></i>
                            </li>
                            <li>
                              <i data-feather="star"></i>
                            </li>
                          </ul>
                          <p class="mb-0 mt-1">35 تومان</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- Suggestion Start -->
              </div>
              <!-- Search Input End -->
            </li>

            <li class="user">
              <div class="dropdown user-dropdown">
                <a href="javascript:void(0)"><i data-feather="user"></i></a>
                <ul class="onhover-show-div">
                  <li><a href="login.html">ورود به سیستم</a></li>
                  <li><a href="register.html">ثبت نام</a></li>
                </ul>
              </div>
            </li>

            <li>
              <div class="dropdown whislist-dropdown">
                <a href="javascript:void(0)"
                  ><i class="bi bi-heart"></i>

                  <span class="notification-label">0</span></a
                >
                <div class="onhover-show-div">
                  <a href="wishlist.html">
                    <img
                      src="/assets/icons/svg/box.svg"
                      class="img-fluid"
                      alt="box"
                    />
                  </a>
                  <div class="content">
                    <a href="wishlist.html">
                      <h6>لیست علاقه مندی شما خالی است!!</h6>
                      <p>بیشتر کاوش کنید و موارد را فهرست کنید.</p>
                    </a>
                  </div>
                </div>
              </div>
            </li>

            <!-- Cart Menu Start -->
            <li>
              <div class="dropdown shopingbag">
                <a href="javascript:void(0)" class="cart-button">
                  <i
                    class="iconsax"
                    icon-name="shopping-cart"
                    style="font-size: 24px"
                  ></i>
                  <span v-if="cardList.length > 0" class="notification-label">
                    {{ cardList.length }}
                  </span>
                </a>
                <a
                  href="javascript:void(0)"
                  class="overlay-cart overlay-common"
                ></a>
                <div class="onhover-cart-dropdown">
                  <div class="onhover-show-div">
                    <div class="dropdown-header">
                      <div class="control">
                        <a href="">سبد خرید</a>
                        <button class="back-cart">
                          <i
                            class="iconsax"
                            icon-name="heart"
                            style="font-size: 24px"
                          ></i>
                        </button>
                      </div>
                    </div>

                    <div class="card-wrap custom-scroll">
                      <!-- Cart Item Start -->
                      <div
                        v-for="(item, index) in cardList"
                        :key="index"
                        class="cart-card media"
                      >
                        <router-link
                          :to="{
                            name: 'Product',
                            params: { id: item.product._id },
                          }"
                        >
                          <img
                            :src="
                              'https://cdn.varenistyle.com/ds02/small/' +
                              item.product.imgs[0]['fileName']
                            "
                            class="img-fluid"
                            alt="product"
                          />
                        </router-link>
                        <div class="media-body">
                          <a style="direction: rtl">
                            <strong>
                              {{ item.product.title }}
                            </strong>
                            <br />

                            <label>
                              {{ item.color.name }}
                            </label>

                            <label>سایز</label>

                            <label
                              style="font-family: 'Vazirmatn FD' !important"
                            >
                              {{ item.size }}
                            </label>
                          </a>
                          <span
                            v-if="item.product.price"
                            style="font-family: 'Vazirmatn FD' !important"
                          >
                            {{ (item.product.price / 10).toLocaleString() }}
                            تومان
                          </span>
                          <!-- <div class="plus-minus">
                            <i class="sub" data-feather="minus"></i>
                            <input type="number" value="1" min="1" max="10" />
                            <i class="add" data-feather="plus"></i>
                          </div> -->
                        </div>
                        <button class="remove-cart">
                          <i data-feather="x"></i>
                        </button>
                      </div>
                      <!-- Cart Item End -->
                    </div>
                    <div class="dropdown-footer">
                      <div class="freedelevery">
                        <p class="terms-condition">
                          ارسال رایگان! برای افزودن محصول بیشتر به سبد خرید خود
                          به خرید ادامه دهید و برای سفارش‌های بیش از
                          <strong>500 تومان</strong>
                        </p>
                        ارسال رایگان دریافت کنید.
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="width: 70%"
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            70%
                          </div>
                        </div>
                      </div>
                      <div class="total-price">
                        <span>مجموع</span>
                        <span>450 تومان</span>
                      </div>

                      <div class="btn-group block-group">
                        <router-link
                          :to="{ name: 'PanelUser-Card' }"
                          class="btn-solid"
                        >
                          مشاهده سبد خرید
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li>
              <router-link to="/user/dashboard">
                <i
                  class="iconsax"
                  icon-name="user-2"
                  style="font-size: 24px"
                ></i>

                ورود / ثبت‌نام
              </router-link>
            </li>
            <!-- Cart Menu End -->
          </ul>
          <!-- Icon Menu End -->
        </div>
        <!-- Menu Right End  -->
      </div>

      <hr />

      <div>
        <ul>
          <li style="margin-left: 70px">
            <i class="bi bi-person"></i>
            دسته‌بندی
          </li>

          <li style="margin-left: 70px">
            <router-link :to="{ name: 'Products' }">
              <i class="bi bi-person"></i>
              محصولات
            </router-link>
          </li>

          <li style="margin-left: 70px">
            <i class="bi bi-person"></i>
            دانستنی‌های مود و استایل
          </li>

          <li style="margin-left: 70px">
            <i class="bi bi-person"></i>
            شگفت انگیز
          </li>
        </ul>

        <hr />
      </div>
    </div>
  </header>
</template>

<script>
import axios from "axios";
export default {
  props: {
    cardList: {
      type: Array,
      default: [],
    },
  },
  components: {},
  data() {
    return {
      dataList: [],
      userAgentDetails: {
        screenType: null,
      },
      urls: [
        { text: "صفحه فرود", urlName: "", icon: "" },
        { text: "وبلاگ", urlName: "", icon: "" },
        { text: "درباره‌ما", urlName: "", icon: "" },
        { text: "تماس با ما", urlName: "", icon: "" },
        { text: "همکاری", urlName: "", icon: "" },
        { text: "موقعیت", urlName: "", icon: "" },
      ],
    };
  },

  methods: {
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    handleUserAgentDetails() {
      this.userAgentDetails = this.$root.getUserAgentDetails();
    },
  },

  created() {
    window.addEventListener("resize", this.handleUserAgentDetails);
    this.handleUserAgentDetails();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleUserAgentDetails);
  },

  mounted() {
    // this.userAgentDetails = this.$root.getUserAgentDetails();
    this.urls = this.urls.reverse();
  },
};
</script>

<style lang="css" scoped>
.liBig {
  width: unset;
  text-align: unset;
}

.liSmall {
  width: 100%;
  text-align: center;
}

.nav_collapse {
  border: 1px solid #efb10054;
  border-radius: 21px;
  padding: 7px;
  box-shadow: 0px -1px 35px -14px rgba(239, 177, 0, 1);
  -webkit-box-shadow: 0px -1px 35px -14px rgba(239, 177, 0, 1);
  -moz-box-shadow: 0px -1px 35px -14px rgba(239, 177, 0, 1);
}

.header-common .nav-wrap .menu-right .icon-menu li a .notification-label {
  top: -17px !important;
}

@media all and (min-width: 992px) {
  .marign-desktop {
    margin-left: 15rem;
  }
}
</style>
