<template>
  <!-- Document Footer Start -->
  <footer class="footer-document footer-document2 ratio_asos mb-xxl">
    <!-- <div class="bg-footer-l">
      <img src="/assets/images/bag/banner/bg-footer-l.png" alt="banner" />
    </div> -->
    <!-- <div class="bg-footer-r">
      <img src="/assets/images/bag/banner/bg-footer-r.png" alt="banner" />
    </div> -->
    <div>
      <div class="container-lg">
        <div class="main-footer">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-8">
                  <h3 style="color: var(--theme-color-brown); font-size: 21px">
                    فروشگاه وارنی‌استایل
                  </h3>

                  <p
                    class="mt-4"
                    style="color: #fff; font-size: 16px; line-height: 1.6"
                  >
                    فروشگاه اینترنتی وارنی‌استایل به عنوان یک مرجع تخصصی خرید
                    جدیدترین مدل کیفهای روزمره و اداری، کیفهای رسمی و مجلسی و
                    مناسب مهمانی، انواع کیفهای دستی و دوشی، کیفهای پاسپورتی،
                    کیفهای کمری و کوله پشتی، ساک و کیفهای ورزشی، چمدان و ساک
                    مسافرتی، کیف ساحلی و پلاژ، انواع کیفهای چند کاره، کیف پول و
                    جاکارتی زنانه و دخترانه در مسیر ارتقاء تجریه خرید اینترنتی
                    کیف و اکسسوری فعالیت می‌کند.
                  </p>
                </div>

                <div class="col-4">
                  <br />
                  <h3 style="color: #fff; font-size: 16px">
                    (با ثبت نام در خبرنامه از جدیترین محصولات باخبر شوید.)
                  </h3>
                  <form
                    action="javascript:void(0)"
                    class="footer-form"
                    data-v-3e1733e7=""
                  >
                    <input
                      required=""
                      type="email"
                      class="form-control"
                      style="
                        display: inline;
                        width: 63%;

                        height: 42px;

                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                      "
                      data-v-3e1733e7=""
                    /><button
                      type="submit"
                      style="
                        background-color: #cea675 !important;
                        color: #183a33 !important;
                        height: 42px;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                      "
                      class="btn btn-solid round-corner line-none btn-solid2"
                      data-v-3e1733e7=""
                    >
                      ارسال
                    </button>
                  </form>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-8">
                  <div class="row">
                    <div class="col-4">
                      <h3
                        style="font-size: 18px; color: var(--theme-color-brown)"
                      >
                        خدمات مشتریان
                      </h3>
                      <h5 style="color: #fff; font-size: 14px; line-height: 2">
                        پاسخ به سوالات متداول
                      </h5>
                      <h5 style="color: #fff; font-size: 14px; line-height: 2">
                        رهگیری محصولات پستی تیپاکس
                      </h5>

                      <h5 style="color: #fff; font-size: 14px; line-height: 2">
                        رهگیری محصولات پست
                      </h5>

                      <h5 style="color: #fff; font-size: 14px; line-height: 2">
                        حفظ حریم شخصی کاربران
                      </h5>

                      <h5 style="color: #fff; font-size: 14px; line-height: 2">
                        رویه بازگشت، لغو و تعویض کالا
                      </h5>
                    </div>
                    <div class="col-4">
                      <h3
                        style="font-size: 18px; color: var(--theme-color-brown)"
                      >
                        لینک ها
                      </h3>
                      <h5 style="color: #fff; font-size: 14px; line-height: 2">
                        خرید کیف زنانه
                      </h5>
                      <h5 style="color: #fff; font-size: 14px; line-height: 2">
                        کیف مردانه
                      </h5>

                      <h5 style="color: #fff; font-size: 14px; line-height: 2">
                        کفش چرم
                      </h5>
                      <h5 style="color: #fff; font-size: 14px; line-height: 2">
                        کالای خواب
                      </h5>
                      <h5 style="color: #fff; font-size: 14px; line-height: 2">
                        حراجی ها
                      </h5>
                    </div>
                    <div class="col-4">
                      <h3
                        style="font-size: 18px; color: var(--theme-color-brown)"
                      >
                        وارنی‌استایل
                      </h3>
                      <h5 style="color: #fff; font-size: 14px; line-height: 2">
                        درباره ما
                      </h5>
                      <h5 style="color: #fff; font-size: 14px; line-height: 2">
                        تماس با ما
                      </h5>
                      <h5 style="color: #fff; font-size: 14px; line-height: 2">
                        قوانین و شرایط
                      </h5>
                      <h5 style="color: #fff; font-size: 14px; line-height: 2">
                        راهنمای خرید
                      </h5>
                      <h5 style="color: #fff; font-size: 14px; line-height: 2">
                        دانستنیهای مد و استایل
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="row">
                    <div class="col-4">
                      <img src="/assets/images/ui/en-1.png" alt="" />
                    </div>
                    <div class="col-4">
                      <img src="/assets/images/ui/en-2.png" alt="" />
                    </div>
                    <div class="col-4">
                      <img src="/assets/images/ui/en-3.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <hr class="mt-4 mb-4" style="border-top: 1px solid #fff" />

              <div class="row mt-4">
                <div class="col mt-2">
                  <img src="/assets/images/logo3.png" alt="" />
                </div>
                <div class="col mt-2">
                  <p style="color: #fff">
                    نشانی: تبریز - .......................
                  </p>
                </div>
                <div class="col mt-2">
                  <p style="color: #fff">
                    تلفن:
                    <a
                      href="tel:+989002700232"
                      style="
                        color: #fff;
                        unicode-bidi: bidi-override;
                        direction: ltr;
                      "
                    >
                      +98 9002700232
                    </a>
                  </p>
                </div>
                <div class="col mt-2">
                  <p style="color: #fff">
                    ایمیل:
                    <a href="tel:+989002700232" style="color: #fff">
                      info@varenistyle.com
                    </a>
                  </p>
                </div>

                <div class="col-4">
                  <div class="row">
                    <div class="col">
                      <img src="/assets/images/ui/soc-1.png" alt="" />
                    </div>
                    <div class="col">
                      <img src="/assets/images/ui/soc-2.png" alt="" />
                    </div>
                    <div class="col">
                      <img src="/assets/images/ui/soc-3.png" alt="" />
                    </div>
                    <div class="col">
                      <img src="/assets/images/ui/soc-4.png" alt="" />
                    </div>
                    <div class="col">
                      <img src="/assets/images/ui/soc-5.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- Document Footer End -->
</template>

<script>
import PrTextLogo from "@/components/custom/PrTextLogo.vue";

export default {
  components: { PrTextLogo },
};
</script>

<style scoped>
.footer-document {
  background: #183a33 !important;
}

.footer-document .main-footer .content-box ul li .nav:hover {
  color: #cea675 !important;
}

.line-style::before {
  background-color: #cea675 !important;
}

.hover-footer-element:hover {
  color: #cea675 !important;
}
</style>
